<template>
  <div class="form">
    <div class="wrapper illustration illustration_dots">
      <Backlink title="questionnaire" service="refMortgage" backlink="/refinance-mortgage/expense" />
      <div class="content">
        <AboutExpenses service="refMortgage" nextlink="/refinance-mortgage/obligations" />
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import AboutExpenses from '@/components/service/AboutExpenses.vue'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Refinance_mortgage_about_expences',
  components: { Backlink, AboutExpenses }
}
</script>
